import React, { useState } from 'react'

import { BigHeader, MediumText, SmallText } from 'src/utils/styles/global-styles'
import { Container, Tabs, Tab, TabButton } from './styles'

import CloudPortal from './cloud-portal'
import MobileApp from './mobile-app'
import Videos from './videos'
import Captions from './captions'

const TABS = {
  cloud: { label: 'Cloud Portal', container: <CloudPortal /> },
  mobile: { label: 'Mobile App', container: <MobileApp /> },
  videos: { label: 'Videos', container: <Videos /> },
  captions: { label: 'Captions', container: <Captions /> },
}

const SkylightPlusGuide = () => {
  const [currentTab, setTab] = useState('cloud')
  return (
    <Container>
      <BigHeader>How to Use Frame Plus</BigHeader>
      <MediumText>
        The Frame Plus subscription allows you to access our cloud portal and mobile app, where you
        can send photos and videos to Skylight, and even add captions!
      </MediumText>
      <MediumText>
        <strong>
          Frame Plus can be used with all your Skylight Frames and by everyone in the family.
        </strong>
      </MediumText>
      <SmallText>
        *Please note that your Frame Plus subscription is linked to the email address you used to
        purchase the frame. Please use that email address to activate the frame. If you used another
        email address, please contact our support team at info@skylightframe.com
      </SmallText>

      <Tabs role="tablist">
        {Object.keys(TABS).map((key) => {
          const active = key === currentTab
          return (
            <Tab key={key} active={active} role="tab">
              <TabButton active={active} onClick={() => setTab(key)} type="button">
                {TABS[key].label}
              </TabButton>
            </Tab>
          )
        })}
      </Tabs>

      {TABS[currentTab].container}
    </Container>
  )
}

export default SkylightPlusGuide
